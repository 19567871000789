@import "../../../styles/common.scss";

.right-sidebar-container {
  width: 7%;
  background-color: rgba(255, 255, 255, 0.985);
  display: flex;
  justify-content: center;

  .right-sidebar {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .add-icon {
      background-color: rgb(215, 215, 14);
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .add-icon:hover {
      cursor: pointer;
      background-color: rgb(228, 228, 112);
    }

    // .sidebar-item {
    //   display: flex;
    //   align-items: center;
    //   font-weight: 100;

    //   font-size: 15px;
    //   gap: 15px;

    //   color: rgba(108, 110, 110, 0.949);
    // }
    // .sidebar-item-selected {
    //   color: rgba(9, 9, 9, 0.949);
    // }

    // .sidebar-item:hover {
    //   color: rgba(8, 8, 8, 0.949);
    // }
  }
}

@media (max-width: 900px) {
  .right-sidebar-container {
    display: none;
  }
}