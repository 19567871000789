@import "../../../styles/common.scss";

.sidebar-container {
  background-color: rgba(255, 255, 255, 0.985);

  display: flex;
  justify-content: center;
  overflow: auto;
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .logo {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
    @media (max-width: 768px) {
      .logo {
        display: none;
      }
      .sidebar-item{
        font-size: 10px;
        gap: 10px;
      }
    }

    .sidebar-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      gap: 15px;
      color: rgba(108, 110, 110, 0.949);
    }

 
    .sidebar-item-selected {
      color: rgba(9, 9, 9, 0.949);
    }

    .sidebar-item:hover {
      color: rgba(8, 8, 8, 0.949);
      transform: scale(1.1);
      transition: transform 0.3s ease-in-out;
    }
  }
}
