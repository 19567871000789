@import "../../styles/common.scss";

.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fff8dd;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    font-size: 35px;
    font-weight: $font-weight-bold;
    text-align: center;
    margin: 10px;
  }
  @media (max-width: 768px) {
    .header {
      font-size: 25px;
    }
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .textbox {
      width: 300px;
    }

    .log-in-button {
      border-radius: 30;
      color: $secondary-color-text;
      padding: 15px;

      display: "flex";
      justify-content: space-between;
      cursor: pointer;
      width: 300px;

      background-color: $primary-color-button;
    }
    .log-in-button:hover {
      background-color: #18318c;
    }

    @media (max-width: 768px) {
      .textbox {
        width: 200px;
      }
      .log-in-button {
        width: 200px;
      }
    }
  }
}
