/* importing google font poppins */

$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$primary-color-button: #090963;

$primary-color-text: #232335;
$secondary-color-text: #e6e6ec;

.error-message {
  color: red;
  font-size: $font-size-small;
  text-align: center;
}

.error-data {
  text-align: center;
  padding: 20px;
  color: #d8000c;
}

//buttons
.common-button-style {
  background-color: #f9ce00 !important;
  color: $primary-color-text !important;
  width: 200px !important;
  height: 40px !important;
  text-align: center !important;
}
.common-button-style:hover {
  background-color: #a6ad6f !important;
}

//form-modal
.dialog {
  backdrop-filter: blur(8px);
  .dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dialog-title {
    font-size: 20px;
    font-weight: 600;
  }
  .close-icon {
    cursor: pointer;
    margin-right: 10px;
  }
}

// table
.table-main {
  .table-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-top-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .data-table {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .column-header {
      color: #646567;
      text-align: center;
    }
  }
}

//Form-style
.form-container {
  width: 300px;
  display: flex;
  flex-direction: column;

  .main-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    .form-buttons {
      display: flex;
      justify-content: space-between;

      .button-main {
        background-color: #f9ce00;
        color: $primary-color-text;
        width: 130px;
        height: 40px;
        text-align: center;
        text-align: center;
        display: inline-block;
      }
      .button-main:hover {
        background-color: #edf897;
      }
    }
  }
}

//pages topbar
.topbar {
  height: 10%;

  .topbar-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h1 {
      font-size: 20px;
      font-weight: $font-weight-bold;
    }
    p {
      font-size: 15px;
      color: #646567;

      font-weight: $font-weight-regular;
    }
  }
}

//delete box
.deletebox {
  backdrop-filter: blur(8px);
  .headName {
    font-size: 20px;
    color: #d8000c;
  }
  .delete-buttons {
    font-size: "20px";
    font-weight: 500;
    background-color: #a6ad6f;
    color: black;
  }
  .delete-buttons:hover {
    background-color: #ebf3ae;
  }
}

//breadcrumb
.breadcrumb-not-active {
  font-size: 15px;
  color: #646567;
  text-decoration: none;
}

.breadcrumb-active {
  font-size: 15px;
  color: #000000;
  text-decoration: none;
}

//profile styles
.user-settings {
  margin-top: 40px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: auto;
  max-width: 400px;
  background-color: #fff8dd;
  .profileItem {
    margin: 8px;
  }
}
