@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif !important;
}

html,
body,
#root,
.app,
.content {
  // font-family: "Poppins", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;

}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-sidebar {
    width: 25%;
    overflow: auto;
  }

  @media (max-width: 768px) {
    .left-sidebar {
      display: none;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff8dd;
  padding: 15px;

  .hamburger-menu {
    display: none;
  }

  @media (max-width: 768px) {
    .hamburger-menu {
      display: block;
    }
  }
}
